import React from 'react';
import { AspectRatio } from '@silkpwa/module/react-component/aspect-ratio';
import { Base } from './base';

const Image = Base('image');

interface Props {
    className?: string;
    aspectRatio?: string;
}

export const ResponsiveImage = ({ className, aspectRatio }: Props) => (
    <AspectRatio ratio={aspectRatio}>
        <Image className={className} />
    </AspectRatio>
);
