import React from 'react';
import { Container } from 'ui/component/container';
import { ResponsiveImage } from 'ui/component/placeholders/responsive-image';
import styles from './style.css';

export const CategoriesBanner = () => (
    <Container
        id="home-categories-banner"
        className={styles.categoriesBanner}
        fallback={(
            <React.Fragment>
                <ResponsiveImage aspectRatio="131:100" />
                <ResponsiveImage aspectRatio="131:100" />
                <ResponsiveImage aspectRatio="131:100" />
            </React.Fragment>
        )}
    />
);
