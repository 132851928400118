import React from 'react';
import { computeWidthStyle } from 'ui/util/compute-width';
import { ReviewStars } from './review-stars';
import styles from './style.css';

function getRating(item) {
    const filteredData = item.rating_votes.filter(obj => obj.value);
    const sum = filteredData.reduce((acc, obj) => acc + Number(obj.value), 0);
    return Math.round(sum / filteredData.length);
}
/* disabling these because the event handlers only exist to aid in
   moderating reviews, and are not meant to be used by end users */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
export const Review = ({
    item,
    displayed,
}) => {
    const rating = getRating(item);
    return (
        <div className={styles.review} style={computeWidthStyle(displayed)}>
            <div className={styles.title}>{item.title}</div>
            {rating &&
                <ReviewStars stars={rating} className={styles.stars} />
            }
            <p className={styles.body}>{item.detail}</p>
            <p className={styles.byLine}>
                {'- '}
                {item.nickname}
            </p>
        </div>
    );
};
/* eslint-enable */
