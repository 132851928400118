import React from 'react';
import { useRecommendations } from '@silkpwa/module/certona';
import { useIsPunchoutInspectOperation } from '@silkpwa/module/store-mode';
import { RecommendedProductsWidget } from './styled';

export const CertonaRecommendations = () => {
    const { recommendations, config } = useRecommendations();
    const isPunchoutInspectOperation = useIsPunchoutInspectOperation();

    if (!recommendations) return null;
    if (!recommendations.length) return null;

    return isPunchoutInspectOperation ? null : (
        <RecommendedProductsWidget
            data={{ items: recommendations }}
            title={config.title}
        />
    );
};
