import React from 'react';
import { HeadTag } from '@silkpwa/module/react-component/head-tag';

export const SEOTags = ({ info }) => (
    <React.Fragment>
        {info.meta_keyword && (
            <HeadTag tag="meta" name="keywords" content={info.meta_keyword} />
        )}
        {info.meta_description && (
            <HeadTag tag="meta" name="description" content={info.meta_description} />
        )}
        {info.canonical && (
            <HeadTag tag="link" rel="canonical" href={info.canonical} />
        )}
        {info.hreflang && (
            <>
                {Object.keys(info.hreflang).map(key => (
                    <HeadTag key={key} tag="link" rel="alternate" href={info.hreflang[key]} hreflang={key} />
                ))}
            </>
        )}
        {info.robots && (
            <HeadTag tag="meta" name="robots" content={info.robots} />
        )}
    </React.Fragment>
);
