import React from 'react';
import reviewStar from 'assets/images/reviewStar.png';
import styles from './style.css';

export const ReviewStars = ({ className, stars }) => (
    <div className={className}>
        {Array.apply(0, new Array(stars)).map((_, index) => (
            <img
                key={`star_${index}`} // eslint-disable-line react/no-array-index-key
                src={reviewStar}
                className={styles.reviewStar}
                alt="Star"
                width="20"
                height="19"
            />
        ))}
    </div>
);
