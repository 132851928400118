import React from 'react';
import { connectReviews } from '@silkpwa/module/react-component/connect-reviews';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import screenSwitch from 'ui/styles/screen-switch.css';
import { Slider } from './slider';
import styles from './style.css';

const HomePageReviewsWidgetImpl = ({ reviews }) => {
    const t = usePhraseTranslater();
    if (!reviews || reviews.length === 0) {
        return null;
    }
    return (
        <div className={styles.reviews}>
            <h2 className={styles.reviewsHeader}>{t('Reviews are In')}</h2>
            <div className={screenSwitch.showOnDesktop}>
                <Slider show={4} items={reviews} />
            </div>
            <div className={`${screenSwitch.showOnLargeMobile} ${screenSwitch.showOnMediumMobile}`}>
                <Slider show={2} items={reviews} />
            </div>
            <div className={`${screenSwitch.showOnSmallMobile} ${screenSwitch.showOnVerySmallMobile}`}>
                <Slider show={1} items={reviews} />
            </div>
        </div>
    );
};

export const HomePageReviewsWidget = connectReviews(HomePageReviewsWidgetImpl);
