import React, { useState, useEffect } from 'react';
import { RichSnippet } from './rich-snippet';

interface IAddress {
    '@type': string;
    'addressLocality': string;
    'addressRegion': string;
    'addressCountry': string;
    'postalCode': string;
    'streetAddress': string;
}

export interface IOrganizationSnippetData {
    '@context': string;
    '@type': string;
    legalName: string;
    description: string;
    url: string;
    address: IAddress;
    logo: string;
    sameAs: string[];
}

/**
 * Adds the organization rich snippet (https://schema.org/Organization) to the frontend of the website
 * @constructor
 */

export const OrganizationRichSnippet: React.FC = () => {
    const [data, setData] = useState(null);
    useEffect(() => {
        const getData = async () => {
            const response = await fetch(`${process.env.MAGENTO_API}/rest/V1/organization-rich-snippet`);
            if (!response.ok) {
                throw new Error(
                    `Unable to fetch organization snippet data ${response.status}`,
                );
            }
            const result = await response.json();
            setData(result);
        };
        getData();
    }, []);
    return <RichSnippet data={data} />;
};
