import React from 'react';
import { ItemSlider } from 'ui/component/item-slider';
import { Review } from './review';
import styles from './style.css';

export const Slider = ({ show, items }) => (
    <div className={styles.slider}>
        <ItemSlider
            show={show}
            items={items}
            itemsClassName={styles.itemsClass}
            arrowClassName={styles.arrowClass}
            Item={Review}
        />
    </div>
);
