import React from 'react';
import { DocumentTitle } from '@silkpwa/module/react-component/document-title';
import { injectProps } from '@silkpwa/redux';
import { connectConfig } from '@silkpwa/module/react-component/connect-config';
import { OrganizationRichSnippet } from 'ui/component/rich-snippets/organization-rich-snippet';
import { SEOTags } from 'ui/component/seo-tags';

import SilkRestappDataConfigInfoInterface = Magento.Definitions.SilkRestappDataConfigInfoInterface;

interface Props {
    ecommerceConfig: SilkRestappDataConfigInfoInterface;
    window: Window;
}

const Head: React.FC<Props> = ({ ecommerceConfig, window }) => {
    const info = {
        canonical: ecommerceConfig.extension_attributes?.canonical || window.location.href.replace(/\?.*$/, '') || null,
        hreflang: ecommerceConfig.extension_attributes?.hreflang || null,
        robots: ecommerceConfig.extension_attributes?.robots || null,
        meta_description: ecommerceConfig.extension_attributes?.cw_homepage_description || null,
    };
    return (
        <React.Fragment>
            <DocumentTitle exact>{ecommerceConfig.extension_attributes?.cw_homepage_title}</DocumentTitle>
            <SEOTags info={info} />
            <OrganizationRichSnippet />
        </React.Fragment>
    );
};

const ConnectedHead = connectConfig(injectProps('window')(Head));

export { ConnectedHead as Head };
