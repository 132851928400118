import React from 'react';
import { useRecommendations } from '@silkpwa/module/certona';
import { Container } from 'ui/component/container';
import { CertonaRecommendations } from 'ui/component/product/product-widget/certona-recommendations';
import styles from './style.css';

/* Temporary hack to remove the recommendations from
the CMS container when certona is on. Later we will have a separate
container for the recommendations which will make this useless. */
const removeRecommendations = content => ({
    ...content,
    props: {
        ...content.props,
        children: content.props.children.slice(1),
    },
});

export const TopWidgets = () => {
    const { config } = useRecommendations();

    return (
        <Container id="home-top-widgets">
            {(content) => {
                if (!content) return null;

                if (
                    config.enabled &&
                    config.scriptUrl &&
                    (config.pagesEnabled || []).indexOf('home') > -1
                ) {
                    return (
                        <div className={styles.topWidgets}>
                            <CertonaRecommendations />
                            <div className={styles.blueTextured}>
                                {removeRecommendations(content)}
                            </div>
                        </div>
                    );
                }

                return (
                    <div className={styles.topWidgets}>
                        {content}
                    </div>
                );
            }}
        </Container>
    );
};
