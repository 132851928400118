import React from 'react';
import { Context } from './context';

export class DocumentTitle extends React.Component<any, any> {
    private _combineTitle;

    private _title;

    constructor(props) {
        super(props);

        this.updateTitle = this.updateTitle.bind(this);
        this.componentDidMount = this.updateTitle;
        this.componentDidUpdate = this.updateTitle;
        this._combineTitle = pageTitle => pageTitle;
    }

    get childTitle() {
        const { children } = this.props;

        if (children instanceof Array) {
            return children.join('');
        }

        return children;
    }

    get nextTitle() {
        const { title } = this.props;
        return title || this.childTitle;
    }

    updateTitle() {
        if (typeof document === 'undefined') return;

        if (this.nextTitle !== this._title) {
            this._title = this.nextTitle;
            document.title = this.combineTitle(this._title);
        }
    }

    combineTitle(title) {
        const { exact } = this.props;

        if (exact) {
            return title;
        }

        return this._combineTitle(title);
    }

    render() {
        return (
            <Context.Consumer>
                {(combineTitle) => {
                    this._combineTitle = combineTitle;
                    this.updateTitle();
                    return null;
                }}
            </Context.Consumer>
        );
    }
}
