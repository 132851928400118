import React from 'react';
import { Container } from 'ui/component/container';
import { MainColumn } from 'ui/component/main-column';
import { ResponsiveImage } from 'ui/component/placeholders/responsive-image';
import { Reviews } from './reviews';
import { FeaturedPeeps } from './featured-peeps';
import { TopWidgets } from './top-widgets';
import { WhyWeRock } from './why-we-rock';
import { CategoriesBanner } from './categories-banner';
import styles from './style.css';

export const Content = () => (
    <MainColumn className="maincolumn-homepage">
        <div className={styles.homePage}>
            <Container
                id="home-hero-banner"
                className={styles.heroBanner}
                fallback={
                    <ResponsiveImage aspectRatio="1280:670" />
            }
            />
            <CategoriesBanner />
            <TopWidgets />
            <Container id="home-all-eyes-on-you" className={styles.allEyesOnYou} />
            <WhyWeRock />
            <FeaturedPeeps />
            <Reviews />
            <Container id="recently-viewed-footer" className={styles.recentlyViewed} />
        </div>
    </MainColumn>
);
